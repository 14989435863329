<template>
    <b-row class="rowbg">
        <b-col md="12">
            <b-card>
                <b-card-title>Roetveeg</b-card-title>
                <b-card-text>
<p><strong>Inleiding</strong></p>
<p>In onze visie op Piet in 2015 schreven wij o.a. het volgende;&nbsp;<em>wellicht is onze visie nu over een aantal jaar ook weer achterhaald, maar wij staan open voor verandering</em>. Daar bleken wij het bij het rechte eind te hebben. Na kritisch naar onze visie uit 2015 gekeken te hebben zien we dat wij hier in grote lijn nog steeds achter staan maar werd het ons ook duidelijk dat Piet dynamisch is, en het is aan ons om mee te gaan met deze veranderingen.&nbsp;</p>
<p>Ook al willen wij de discussie, en onze visie, niet gebruiken als &lsquo;marketingtool&rsquo; vinden wij dat wij onze visie juist nu moeten uitspreken. Als vertolker van het karakter Piet &amp; Sint hebben wij een voorbeeldrol in de discussie.</p>
<p><strong>Wij zijn tegen discriminatie en racisme</strong></p>
<p>Wij zijn nadrukkelijk tegen discriminatie en racisme, op welke grond dan ook. In alles wat wij doen beogen wij niemand te discrimineren, te beledigen of te kwetsen. Wanneer onze diensten of uitingen voor sommigen wel als discriminerend of anderszins vervelend overkomen staan wij open voor de dialoog en nemen hier een actieve rol in aan.</p>
<p>Wij distanti&euml;ren ons nadrukkelijk van partijen waarbij het vertolken van Sint of Piet samen gaat met racistische of discrimineerde uitlatingen.</p>
<p><strong>Het Sinterklaasfeest</strong></p>
<p>Het Sinterklaasfeest staat voor ons voor een gezellig familiefeest, waarbij Piet en Sint samen zorgen voor het feest. Piet is hierbij het toegankelijke karakter voor kinderen die veel interactie zoekt. Een sprookjesfiguur die tot de verbeelding spreekt en extra dimensie krijgt door roet van de schoorsteen. Wij dragen als bedrijf bij aan een algemeen geaccepteerd beeld van Piet en een plezierige beleving voor&nbsp;iedereen.</p>
<p><strong>Rolverdeling</strong></p>
<p>We vinden het belangrijk om te benoemen dat wij sinds ons bedrijf bestaat Piet en Sint neerzetten als gelijken. Sinterklaas spreekt nooit over &lsquo;zijn&rsquo; Pieten en gedraagt zich ook niet op die manier. Het verschil tussen Sint &amp; Piet komt voort uit het leeftijdsverschil. Wij besteden veel aandacht aan ongewenste aspecten die een ongelijk beeld in de hand werken. Zo vragen wij naast de stoel voor Sint ook een stoel voor Piet neer te zetten. Ze zijn tenslotte beide een even gewenste gast.</p>
<p><strong>Uiterlijk van Piet</strong></p>
<p>Piet is in de afgelopen decennia op een natuurlijke manier ook al veranderd. Sinds 2015 kiezen wij voor minder felle lippenstift en draagt Piet geen oorbellen meer. En als je in de geschiedenis duikt zijn er nog veel meer zaken veranderd. Het uiterlijk van Piet is duidelijk geen vaststaand gegeven. Zo zie je ook in onze videoclips dat er sinds 2016 steeds minder roet op het gelaat van Piet komt.</p>
<p>Een Piet anno 2021 heeft voor ons roetvegen. Het karakter Piet bestaat verder uit vele onderdelen; kostuum, baret, kraag, make-up en pruik. Het totaal van deze onderdelen maakt Piet tot een eigen karakter dat duidelijk herkenbaar is. Wij vinden tegelijk dat deze onderdelen ook aan verandering onderhevig kunnen zijn en sluiten niet uit dat Piet in de toekomst ook stijl haar of een andere kleur haar kan hebben.</p>
<p>Elke traditie is aan verandering onderhevig. En wellicht is de visie die wij nu hebben, over een aantal jaar ook weer achterhaald, maar wij staan open voor verandering(-en).</p>
<p><strong>Veranderen kost tijd</strong></p>
<p>Wij hebben lang zowel traditionele Piet als roetveegveegpiet aangeboden. Deze tijd was nodig om een breed draagvlak te cre&euml;ren. Samen hebben wij besloten dat het vanaf heden niet meer mogelijk is om traditionele Piet te boeken, dat vinden wij niet meer van deze tijd.</p>
<p>Ook met onze acteurs blijven wij actief in gesprek om hun standpunt te horen en mee te nemen in het ontwikkelen van onze visie. Van alle acteurs verwachten wij dat zij onze visie respecteren en wij respecteren tegelijk ook hun persoonlijke visie. Met acteurs en klanten stemmen we af of we tot een samenwerking kunnen komen waar iedereen zich prettig bij voelt.</p>
<p><strong>Kinderen als voorbeeld</strong></p>
<p>Het Sinterklaasfeest is in de eerste plaats een feest voor kinderen. Als Piet er de komende jaren weer iets anders gaat uitzien zijn de kinderen de eersten die dit accepteren. Dit wordt ge&iuml;llustreerd door het verhaal van de schooljuf die zich voor de klas omkleedt tot Sint, waarbij ze voor de kinderen direct de echte Sinterklaas werd. Laten we een voorbeeld nemen aan onze kinderen en ons openstellen voor veranderingen. Zo blijft het feest dat wij dolgraag willen vieren bestaan:&nbsp;een gezellig familiefeest!</p>
<p><strong>Behoefte om van gedachte te wisselen over dit stuk? Wij nodigen je graag uit voor een kop koffie!</strong></p>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'Roetveeg'
    }
</script>

<style>

</style>